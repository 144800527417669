import run from 'xyz/src/app/run'

import assets  from './app/assets'
import locale  from './app/locale'
import style   from './app/styles/index.css'
import version from './app/version'

run({
    assets,
    locale,
    style,
    version
}) ;
